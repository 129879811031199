<template>
    <LoadingView v-if="loading" />
    <template v-else>
        <b-alert v-model="showErrorAlert" variant="danger" dismissible class="mb-0">
            {{ isNonNullable(generalError) ? generalError : '' }}
        </b-alert>
        <div class="d-flex flex-column p-4">
            <div class="d-flex gap-4">
                <b-form-input v-model="filter" placeholder="Search" class="mb-2" />
                <b-button variant="primary" @click="downloadResults()"> Download Results </b-button>
            </div>

            <div class="d-flex flex-row gap-3 mb-2 align-items-baseline">
                <span>Search for:</span>
                <b-form-checkbox v-model="searchCompetencyId">Competency ID</b-form-checkbox>
                <b-form-checkbox v-model="searchSource">Source</b-form-checkbox>
            </div>
            <div class="d-flex flex-row gap-3 mb-4 align-items-baseline">
                <span>Sorty by:</span>
                <b-form-checkbox v-model="sortByWeight">Weight</b-form-checkbox>
            </div>
            <TaskAssessmentComponent
                v-for="taskScore in sortedTaskScores"
                :key="taskScore.competencyId"
                :task-score="taskScore" />
            Missing indicators:
            {{ missingTaskScores }}
        </div>
    </template>
</template>

<script setup lang="ts">
import { useErrorAlert } from '@/composables/useErrorAlert';
import { useNonNullSimulation } from '@/composables/useSimulationInstance';
import { supabaseClient } from '@/services/supabase';
import type { TaskAssessment } from '@shared-types/competencies';
import { computed, ref } from 'vue';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import { provideCompetencies } from '@/composables/useCompetencies';
import * as XLSX from 'xlsx';
import { max, min } from 'mathjs';

const simulation = useNonNullSimulation();

const { indicatorCompetencyIds } = provideCompetencies('mteApply');

async function loadTaskAssessment() {
    const { data, error } = await supabaseClient
        .from('assessments')
        .select('*')
        .eq('simulation_id', simulation.value.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();

    loading.value = false;
    if (error) {
        generalError.value = error.message;
    } else {
        taskScores.value = (data?.scores ?? []) as TaskAssessment[];
    }
}

loadTaskAssessment();

const loading = ref(true);
const { error: generalError, showErrorAlert } = useErrorAlert<string>();

const taskScores = ref<TaskAssessment[]>([]);

const missingTaskScores = computed(() => {
    return (
        indicatorCompetencyIds.value?.filter((competencyId) => {
            return !taskScores.value.some((taskScore) => taskScore.competencyId === competencyId);
        }) ?? []
    );
});

const filter = ref('');
const searchCompetencyId = ref(true);
const searchSource = ref(true);

const sortByWeight = ref(false);

const filteredTaskScores = computed(() => {
    const filters: Array<(task: TaskAssessment) => boolean> = [];
    if (searchCompetencyId.value) {
        filters.push((task: TaskAssessment) => {
            return task.competencyId.toLowerCase().includes(filter.value.toLowerCase());
        });
    }
    if (searchSource.value) {
        filters.push((task: TaskAssessment) => {
            return hasMatchingSource(task, filter.value);
        });
    }

    return taskScores.value.filter((task) => filters.some((filter) => filter(task)));
});

const sortedTaskScores = computed(() => {
    const sorters: Array<(a: TaskAssessment, b: TaskAssessment) => number> = [];
    if (sortByWeight.value) {
        sorters.push((a, b) => {
            return a.score.variance - b.score.variance;
        });
    }

    return filteredTaskScores.value.slice().sort((a, b) => {
        return sorters.reduce((acc, sorter) => {
            return acc !== 0 ? acc : sorter(a, b);
        }, 0);
    });
});

function hasMatchingSource(taskScore: TaskAssessment, filter: string): boolean {
    if (isNullable(taskScore.source)) {
        return false;
    }

    if (typeof taskScore.source === 'string') {
        return taskScore.source.toLowerCase().includes(filter.toLowerCase());
    } else {
        return taskScore.source.children.some((child) => hasMatchingSource(child, filter));
    }
}

function getFlattenedScoreData(data: TaskAssessment[]): any[] {
    let newData: any[] = [];

    data.forEach((taskScore) => {
        let obj: any = {};
        if (typeof taskScore.source === 'string') {
            obj['competencyId'] = '';
            obj['source'] = taskScore.source;
        } else {
            obj['competencyId'] = taskScore.competencyId;
            obj['source'] = taskScore.source?.modifier;
        }

        obj.score = taskScore.score.score.toFixed(2);
        obj.weight = (1 / taskScore.score.variance).toFixed(2);

        if (typeof taskScore.source !== 'string') {
            let a = taskScore.source?.children.map((a) => a.score.score).filter(isNonNullable);
            if (isNonNullable(a)) {
                obj.min = min(a).toFixed(2);
                obj.max = max(a).toFixed(2);
            }
        }

        newData.push(obj);

        if (typeof taskScore.source !== 'string') {
            newData.push(...getFlattenedScoreData(taskScore.source?.children || []));
        }
    });

    return newData;
}

function downloadResults() {
    let data = getFlattenedScoreData(sortedTaskScores.value);

    // Convert the data to a worksheet if you don't already have one
    console.log(sortedTaskScores.value);
    console.log(data);
    var ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, simulation.value.scenario.internalName);

    // Generate and download the Excel file
    XLSX.writeFile(wb, 'assessment_' + simulation.value.name + '.xlsx');
}
</script>
