import { listenToTable, supabaseClient } from '@/services/supabase';
import { maybeThrowError } from '@/utils/error-helpers';
import type { ScenarioDefinition } from '@shared-types/scenario-definition';
import type {
    SimulationResults,
    SimulationDecisionRow,
    SimulationDecisions,
    SimulationResultsRow,
    SimulationRow,
    SimulationView,
    TeamDecisions,
    TeamResults,
    SimulationUpdate,
    TeamKey,
} from '@/types/simulation';
import decamelize from 'decamelize';
import decamelizeKeys, { type DecamelizeKeys } from 'decamelize-keys';
import camelcaseKeys from 'camelcase-keys';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import { EdgeFunctionsManager } from '@/utils/edge-functions-manager';
import type { BTableProviderContext, BTableProviderResult } from '@/types/component.interfaces';
import type { PeriodKey } from '@/types/scenario-definition';
import type {
    TeamDecisionsStatus,
    SimulationUserRoleDetails,
    TeamWithUsers,
    SimulationCollaborationEventsRow,
} from '@shared-types/simulation';
import type { CollaborationEvent } from '@/types/decisions';
import { createInjectionState } from '@vueuse/core';

export async function getSimulationsCount(archived: boolean = false): Promise<number> {
    const { count, error } = await supabaseClient
        .from('simulations')
        .select('*', { count: 'exact', head: true })
        .eq('archived', archived);
    maybeThrowError(error, 'errors.frontend.simulationCountFetchFailed');
    return count ?? 0;
}

export async function getNextSimulationNumber(): Promise<number> {
    const { data, error } = await supabaseClient.rpc('get_next_simulation_number');
    if (isNonNullable(error) || isNullable(data)) {
        throw new Error('Failed to fetch next simulation number.');
    }
    return data as unknown as number;
}

export async function updateSimulation(simulationID: string, update: SimulationUpdate) {
    const { error } = await supabaseClient.from('simulations').update(decamelizeKeys(update)).eq('id', simulationID);
    maybeThrowError(error, 'errors.frontend.simulationSaveFailed');
}

export async function getSimulations(archived: boolean = false): Promise<SimulationView[]> {
    const { data, error } = await supabaseClient
        .from('simulations_view')
        .select('*')
        .eq('archived', archived)
        .order('start_date', { ascending: true });
    maybeThrowError(error, 'errors.frontend.simulationsFetchFailed');
    return camelcaseKeys(data ?? []) as SimulationView[];
}

export async function getSimulationWithStatus(simulationID: string): Promise<SimulationView | undefined> {
    const { data, error } = await supabaseClient.from('simulations_view').select('*').eq('id', simulationID);
    maybeThrowError(error, 'errors.frontend.simulationFetchFailed');
    const simulation = data?.[0];
    if (isNullable(simulation)) {
        return undefined;
    }
    return camelcaseKeys(simulation) as SimulationView;
}

export async function getSimulation(simulationID: string): Promise<SimulationRow | undefined> {
    const { data, error } = await supabaseClient.from('simulations').select('*').eq('id', simulationID);
    maybeThrowError(error, 'errors.frontend.simulationFetchFailed');
    const simulation = data?.[0];
    if (isNullable(simulation)) {
        return undefined;
    }
    return camelcaseKeys(simulation) as SimulationRow;
}

export function listenToSimulation(simulationID: string, onChange: (simulation?: SimulationRow) => void) {
    return listenToTable<'simulations', SimulationRow>('simulations', onChange, `id=eq.${simulationID}`);
}

export async function getSimulationsAsProvider(
    archived: boolean = false,
    { filter, currentPage, perPage, sortBy, sortDesc }: BTableProviderContext,
): Promise<BTableProviderResult<SimulationView>> {
    const query = supabaseClient.from('simulations_view').select('*', { count: 'exact' }).eq('archived', archived);

    if (isNonNullable(filter) && filter !== '') {
        query.or(`name.ilike.%${filter}%`);
    }

    if (isNonNullable(sortBy)) {
        query.order(decamelize(sortBy) as keyof DecamelizeKeys<SimulationView>, { ascending: !sortDesc });
    }

    const currentItemIndex = (currentPage - 1) * perPage;
    query.range(currentItemIndex, currentItemIndex + perPage - 1);

    const { data, error, count } = await query;

    maybeThrowError(error, 'errors.frontend.simulationsFetchFailed');
    return { items: camelcaseKeys((data as never) ?? []), totalCount: count ?? 0 };
}

export async function deleteSimulations(simulationIDs: string[]): Promise<void> {
    const { error } = await supabaseClient.from('simulations').delete().in('id', simulationIDs);
    maybeThrowError(error, 'errors.frontend.simulationDeleteFailed');
}

export async function archiveSimulations(simulationIDs: string[]): Promise<void> {
    const { error } = await supabaseClient
        .from('simulations')
        .update({ archived: true, manually_restored: null })
        .in('id', simulationIDs);
    maybeThrowError(error, 'errors.frontend.simulationArchiveFailed');
}

export async function restoreSimulations(simulationIDs: string[]): Promise<void> {
    const { error } = await supabaseClient
        .from('simulations')
        .update({ archived: false, manually_restored: true })
        .in('id', simulationIDs);
    maybeThrowError(error, 'errors.frontend.simulationRestoreFailed');
}

// Get all period results of a simulation that the user has access to.
// Since both instructors and participants have full read access, it will return all results for both.
export async function getSimulationResults(simulationID: string): Promise<SimulationResults> {
    const { data: simulationData, error } = await supabaseClient
        .from('simulation_results')
        .select('team_key, period_key, data')
        .eq('simulation_id', simulationID);
    maybeThrowError(error, 'errors.frontend.simulationFetchFailed');

    const res: SimulationResults = {};
    for (const { teamKey, periodKey, data } of camelcaseKeys(simulationData ?? [])) {
        const team = teamKey as TeamKey,
            period = periodKey as PeriodKey;
        const periodResults = res[period];
        const teamResults = data as TeamResults;
        if (isNullable(periodResults)) {
            res[period] = { [team]: teamResults };
        } else {
            periodResults[team] = teamResults;
        }
    }
    return res;
}

export function listenToSimulationResults(
    simulationID: string,
    onChange: (simulationResults?: SimulationResultsRow) => void,
) {
    return listenToTable('simulation_results', onChange, `simulation_id=eq.${simulationID}`);
}

// Get all decisions of a simulation that the user has access to. For instructors, these will include all teams' decisions.
// Participants only have read access on their own team's decisions.
export async function getSimulationDecisions(simulationID: string): Promise<SimulationDecisions | undefined> {
    const { data, error } = await supabaseClient
        .from('simulation_decisions')
        .select('team_key, period_key, decisions, initial_decisions, has_been_submitted')
        .eq('simulation_id', simulationID);
    maybeThrowError(error, 'errors.frontend.simulationFetchFailed');

    const res: SimulationDecisions = {};
    for (const { teamKey, periodKey, decisions, initialDecisions, hasBeenSubmitted } of camelcaseKeys(data ?? [])) {
        const teamDecisionsStatus: TeamDecisionsStatus = {
            decisions: decisions as TeamDecisions,
            initialDecisions: initialDecisions as TeamDecisions,
            hasBeenSubmitted,
        };
        const team = teamKey as TeamKey,
            period = periodKey as PeriodKey;
        if (isNullable(res[period])) {
            res[period] = { [team]: teamDecisionsStatus };
        } else {
            res[period]![team] = teamDecisionsStatus;
        }
    }
    return res;
}

export function listenToSimulationDecisions(
    simulationID: string,
    onChange: (simulationDecisions?: SimulationDecisionRow) => void,
) {
    return listenToTable('simulation_decisions', onChange, `simulation_id=eq.${simulationID}`);
}

export async function getSimulationCollaborationEvents(
    simulationID: string,
    teamKey: TeamKey,
    periodKey: PeriodKey,
    abortController?: AbortController,
): Promise<CollaborationEvent[]> {
    let rpcCall = supabaseClient.rpc('get_collaboration_events', {
        simulation_id: simulationID,
        period_key: periodKey,
        team_key: teamKey,
    });
    if (isNonNullable(abortController)) {
        rpcCall = rpcCall.abortSignal(abortController.signal);
    }
    const { data, error } = await rpcCall;
    if (error?.code === '20') {
        return [];
    }
    maybeThrowError(error, 'errors.frontend.simulationFetchFailed');
    return (data?.[0].events ?? []) as CollaborationEvent[];
}

export function listenToSimulationCollaborationEvents(
    simulationID: string,
    teamKey: TeamKey,
    periodKey: PeriodKey,
    onChange: (teamDecisions?: SimulationCollaborationEventsRow) => void,
) {
    // Remove this once we can filter by multiple columns https://github.com/supabase/realtime-js/issues/97
    function filteredOnChange(data: SimulationCollaborationEventsRow | undefined) {
        if (isNullable(data) || data.teamKey !== teamKey || data.periodKey !== periodKey) {
            return;
        }
        onChange(data);
    }
    return listenToTable('simulation_collaboration_events', filteredOnChange, `simulation_id=eq.${simulationID}`);
}

async function getSimulationUserRoles(simulationID: string): Promise<SimulationUserRoleDetails[]> {
    const { data, error } = await supabaseClient.rpc('get_simulation_user_details', {
        simulation_id_input: simulationID,
    });
    maybeThrowError(error, 'errors.frontend.simulationUserRoleFetchFailed');
    return camelcaseKeys(data ?? []);
}

export async function getTeamsWithUsers(
    simulationID: string,
    scenario?: ScenarioDefinition | Promise<ScenarioDefinition>,
): Promise<TeamWithUsers[]> {
    const [scenarioDefinition, simulationUserRoles] = await Promise.all([
        scenario ??
            EdgeFunctionsManager.getScenarioDefinition({
                simulationID,
            }),
        getSimulationUserRoles(simulationID),
    ]);

    const teamUsers: Record<string, SimulationUserRoleDetails[]> = {};

    for (const role of simulationUserRoles) {
        if (!(role.teamKey in teamUsers)) {
            teamUsers[role.teamKey] = [];
        }
        teamUsers[role.teamKey].push(role);
    }

    return Object.entries(teamUsers)
        .toSorted(([teamA], [teamB]) => scenarioDefinition.teams[teamA].order - scenarioDefinition.teams[teamB].order)
        .map(([team, users]) => ({ key: team as TeamKey, ...scenarioDefinition.teams[team], users }));
}

export function getTeamName(teamNumber: number): string {
    return `Team ${teamNumber + 1}`;
}

interface SimulationServiceContract {
    submitDecisions(
        simulationID: string,
        teamKey: TeamKey,
        periodKey: PeriodKey,
        decisions: TeamDecisions,
        hasBeenSubmitted?: boolean,
    ): Promise<void>;
}

export class SimulationService implements SimulationServiceContract {
    async submitDecisions(
        simulationID: string,
        teamKey: TeamKey,
        periodKey: PeriodKey,
        decisions: TeamDecisions,
        hasBeenSubmitted = true,
    ) {
        const { error } = await supabaseClient
            .from('simulation_decisions')
            .update({
                decisions,
                has_been_submitted: hasBeenSubmitted,
            })
            .eq('simulation_id', simulationID)
            .eq('team_key', teamKey)
            .eq('period_key', periodKey);
        maybeThrowError(error, 'errors.frontend.decisionSubmitFailed');
    }
}

export class MockSimulationService implements SimulationServiceContract {
    async submitDecisions() {}
}

export function provideSimulationService(simulationService: SimulationServiceContract) {
    const [_provideSimulationService, _useSimulationServiceNullable] = createInjectionState(() => simulationService);
    useSimulationServiceNullable = _useSimulationServiceNullable;
    return _provideSimulationService();
}

let useSimulationServiceNullable: () => SimulationServiceContract | undefined = () => undefined;

export const useSimulationService = () => useSimulationServiceNullable()!;
