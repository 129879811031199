<template>
    <div class="card card-sm mb-2">
        <div class="card-body d-flex flex-column">
            <span>
                {{ $st(competencyFramework?.competencies[taskScore.competencyId].name) }}
                <span class="text-secondary">
                    {{ taskScore.competencyId }}
                </span>
            </span>
            <span class="fw-bold"
                >Score: {{ taskScore.score.score.toFixed(2) }}, Weight:
                {{ (1 / taskScore.score.variance).toFixed(2) }}
            </span>
            <template v-if="isNonNullable(taskScore.source) && typeof taskScore.source === 'string'">
                Source: {{ taskScore.source }}
            </template>
            <b-accordion v-else-if="isNonNullable(taskScore.source) && 'children' in taskScore.source" class="mt-3">
                <b-accordion-item>
                    <template #title> Source: {{ taskScore.source.modifier }} </template>
                    <TaskAssessmentComponent
                        v-for="child in taskScore.source.children"
                        :key="child.competencyId"
                        :task-score="child" />
                </b-accordion-item>
            </b-accordion>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCompetencies } from '@/composables/useCompetencies';
import { isNonNullable } from '@/utils/nullability-helpers';
import type { TaskAssessment } from '@shared-types/competencies';

interface TaskAssessmentCompomentProps {
    taskScore: TaskAssessment;
}

defineProps<TaskAssessmentCompomentProps>();

const { competencyFramework } = useCompetencies();
</script>
