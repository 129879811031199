import { createClient, type RealtimeChannel } from '@supabase/supabase-js';
import type { Database } from '@/types/database.types';
import camelcaseKeys, { type CamelCaseKeys } from 'camelcase-keys';
import type { DatabaseTables, RowOfTable } from '@/types/utility-types';

export const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
export const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabaseClient = createClient<Database>(supabaseUrl, supabaseAnonKey);

export const supabaseFunctionsUrl = (() => {
    const isPlatform = supabaseUrl.match(/(supabase\.co)|(supabase\.in)/);
    if (isPlatform) {
        const urlParts = supabaseUrl.split('.');
        return `${urlParts[0]}.functions.${urlParts[1]}.${urlParts[2]}`;
    } else {
        return `${supabaseUrl}/functions/v1`;
    }
})();

type RealtimeFilters = 'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte';

type DatabaseRealtimeFilter<T extends DatabaseTables> = `${keyof RowOfTable<T> & string}=${RealtimeFilters}.${string}`;

export function listenToTable<
    T extends DatabaseTables,
    C extends CamelCaseKeys<RowOfTable<T>> = CamelCaseKeys<RowOfTable<T>>,
>(table: T, onChange: (data?: C) => void, filter?: DatabaseRealtimeFilter<T>): RealtimeChannel {
    return supabaseClient
        .channel(`public:${table}`)
        .on<RowOfTable<T>>('postgres_changes', { event: '*', schema: 'public', table, filter }, (payload) => {
            if (payload.eventType === 'DELETE') {
                onChange(undefined);
                return;
            }
            onChange(camelcaseKeys(payload.new) as C);
        })
        .subscribe();
}
