import { asyncComputed, createInjectionState } from '@vueuse/core';
import { EdgeFunctionsManager } from '@/utils/edge-functions-manager';
import { type CompetencyStructure, type CompetencyFramework } from '@shared-types/competencies';
import { computed } from 'vue';
import { isNullable } from '@/utils/nullability-helpers';

const [provideCompetencies, useCompetenciesNullable] = createInjectionState((frameworkId: string) => {
    const competencyFramework = asyncComputed<CompetencyFramework | undefined>(
        async () => EdgeFunctionsManager.getCompetencyFramework(frameworkId),
        undefined,
    );

    const indicatorCompetencyIds = computed(() => {
        if (isNullable(competencyFramework.value)) {
            return undefined;
        }

        let indicators: string[] = Object.keys(competencyFramework.value.competencies).map(
            (competencyId) => competencyId,
        );

        // Remove all indicators that are not leafs
        function removeNonLeafs(structure: CompetencyStructure): void {
            if (structure.children) {
                indicators = indicators.filter((competencyId) => structure.competencyID !== competencyId);
                structure.children.forEach(removeNonLeafs);
            }
        }

        competencyFramework.value.structure.forEach(removeNonLeafs);

        return indicators;
    });

    return {
        competencyFramework,
        indicatorCompetencyIds,
    };
});

export { provideCompetencies };
export const useCompetencies = () => useCompetenciesNullable()!;
