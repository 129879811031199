import type { TranslationKey } from '@/i18n';
import { isNonNullable } from '@/utils/nullability-helpers';
import type { AuthError, PostgrestError } from '@supabase/supabase-js';
import type { StorageError } from '@supabase/storage-js';
import Logger from 'js-logger';

/**
 * An error that is thrown by the frontend and is meant to be displayed to the user.
 * Other than the regular Error, it requires a translation key that will be used to display the error message.
 */
export class FrontendError extends Error {
    constructor(public key: TranslationKey) {
        super(key);
    }
}

const logger = Logger.get('ErrorHandler');

export function maybeThrowError(
    error: PostgrestError | AuthError | StorageError | null,
    message: TranslationKey,
): void {
    if (isNonNullable(error)) {
        logger.error(error);
        throw new FrontendError(message);
    }
}

/**
 * Throws an error if the given error is not null or undefined. Only used when the error will be handled by
 * another error handler.
 */
export function maybeThrowInternalError(error: PostgrestError | Error | null | undefined): void {
    if (isNonNullable(error)) {
        throw error;
    }
}
