import { createApp } from 'vue';
import '@/assets/scss/theme.scss';
import App from './App.vue';
import '@/utils/logger-config';
import Logger from 'js-logger';
import router from '@/router';
import Notifications from '@kyvg/vue3-notification';
import { createBootstrap } from 'bootstrap-vue-next';
import i18n, { initializeLanguage } from './i18n';
import posthog from 'posthog-js';
import { st, maybeSt } from './i18n/localized-string';
import { initializeCharts } from '@/utils/charts-setup';
import * as Sentry from '@sentry/vue';
import { SupabaseClient } from '@supabase/supabase-js';
import { supabaseUrl } from './services/supabase';
import { supabaseIntegration } from '@supabase/sentry-js-integration';

const app = createApp(App);

if (import.meta.env.PROD) {
    app.config.errorHandler = () => null;
    app.config.warnHandler = () => null;
} else if (import.meta.env.DEV) {
    Logger.get('Main').log('Running in Development Mode');
}

initializeLanguage();

app.use(router);
app.use(i18n);
app.use(createBootstrap());
app.use(Notifications);

app.use(({ config }) => {
    config.globalProperties.$st = st;
    config.globalProperties.$maybeSt = maybeSt;
});

initializeCharts();

const posthogApiKey = import.meta.env.VITE_POSTHOG_API_KEY ?? '';
if (posthogApiKey !== '') {
    posthog.init(posthogApiKey, { api_host: 'https://eu.posthog.com' });
}

const dsn = import.meta.env.VITE_SENTRY_DSN ?? '';
if (dsn !== '') {
    Sentry.init({
        app,
        dsn,
        enabled: import.meta.env.PROD,
        integrations: [
            supabaseIntegration(SupabaseClient, Sentry, {
                tracing: true,
                breadcrumbs: true,
                errors: true,
            }),
            Sentry.browserTracingIntegration({
                router,
                shouldCreateSpanForRequest: (url) => !url.startsWith(`${supabaseUrl}/rest`),
            }),
        ],
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
}

app.mount('#app');
