import type {
    CartesianScaleOptions,
    ChartConfiguration,
    ChartType,
    Scale,
    ScriptableScaleContext,
    TooltipItem,
    TooltipModel,
} from 'chart.js';
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    RadarController,
    RadialLinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import { getCSSVariableValue } from './css-helpers';

let initializedCharts = false;

export function initializeCharts() {
    if (initializedCharts) {
        return;
    }

    Chart.register(
        ArcElement,
        BarController,
        BarElement,
        CategoryScale,
        ChartDataLabels,
        DoughnutController,
        Filler,
        Legend,
        LinearScale,
        LineController,
        LineElement,
        PointElement,
        RadarController,
        RadialLinearScale,
        Title,
        Tooltip,
    );

    Chart.defaults.responsive = true;
    Chart.defaults.animation = false;
    Chart.defaults.maintainAspectRatio = false;

    // Default
    Chart.defaults.color = getCSSVariableValue('--bs-chart-default-color');
    Chart.defaults.font.family = 'Cerebri Sans';
    Chart.defaults.font.size = 13;

    // Layout
    Chart.defaults.layout.padding = 0;

    // Datalabels
    Chart.defaults.plugins.datalabels = {
        ...Chart.defaults.plugins.datalabels,
        anchor: 'end',
        clamp: true,
        display: false, // Has to be enabled manually
        align: 'top',
        offset: 3,
        textAlign: 'center',
    };

    // Legend
    Chart.defaults.plugins.legend.display = false;

    const primary700 = getCSSVariableValue('--bs-chart-primary-700');

    // PointElement
    Chart.defaults.elements.point.radius = 3;
    Chart.defaults.elements.point.backgroundColor = primary700;

    // LineElement
    Chart.defaults.elements.line.tension = 0.4;
    Chart.defaults.elements.line.borderWidth = 3;
    Chart.defaults.elements.line.borderColor = primary700;
    Chart.defaults.elements.line.backgroundColor = 'transparent';
    Chart.defaults.elements.line.borderCapStyle = 'round';

    // BarElement
    Chart.defaults.elements.bar.backgroundColor = primary700;
    Chart.defaults.elements.bar.borderWidth = 0;
    Chart.defaults.elements.bar.borderRadius = 6;
    Chart.defaults.elements.bar.borderSkipped = false;
    Chart.defaults.datasets.bar.maxBarThickness = 15;
    Chart.defaults.datasets.bar.categoryPercentage = 0.5;

    // RadarController
    Chart.defaults.datasets.radar.tension = 0;
    Chart.defaults.datasets.radar.pointRadius = 0;
    Chart.defaults.datasets.radar.pointHoverRadius = 0;
    Chart.defaults.scales.radialLinear.pointLabels.font = {
        size: 13,
    };

    // ArcElement
    Chart.defaults.elements.arc.backgroundColor = primary700;
    Chart.defaults.elements.arc.borderColor = getCSSVariableValue('--bs-chart-arc-border-color');
    Chart.defaults.elements.arc.borderWidth = 0;
    Chart.defaults.elements.arc.hoverBorderColor = getCSSVariableValue('--bs-chart-arc-hover-border-color');

    // Tooltip
    Chart.defaults.plugins.tooltip.enabled = false;
    Chart.defaults.plugins.tooltip.mode = 'index';
    Chart.defaults.plugins.tooltip.intersect = false;
    Chart.defaults.plugins.tooltip.position = 'nearest';
    Chart.defaults.plugins.tooltip.callbacks.label = externalTooltipLabelCallback;

    // DoughnutController
    // Chart.defaults.datasets.doughnut.cutout = '83%';

    // LinearScale
    Chart.defaults.scales.linear.border = {
        ...Chart.defaults.scales.linear.border,
        ...{ display: false, dash: [2], dashOffset: 2 },
    };
    Chart.defaults.scales.linear.grid = {
        ...Chart.defaults.scales.linear.grid,
        ...{
            color: ({ tick }: ScriptableScaleContext) => {
                if (tick.value === 0) {
                    return getCSSVariableValue('--bs-chart-gray-600');
                }
                return getCSSVariableValue('--bs-chart-grid-line-color');
            },
            drawTicks: false,
        },
    };

    Chart.defaults.scales.linear.beginAtZero = true;
    Chart.defaults.scales.linear.ticks.padding = 10;
    Chart.defaults.scales.linear.ticks.stepSize = 25;
    Chart.defaults.scales.linear.ticks.precision = 2;

    // CategoryScale
    Chart.defaults.scales.category.offset = true;
    Chart.defaults.scales.category.border = { ...Chart.defaults.scales.category.border, ...{ display: false } };
    Chart.defaults.scales.category.grid = {
        ...Chart.defaults.scales.category.grid,
        ...{ display: false, drawTicks: false, drawOnChartArea: false },
    };
    Chart.defaults.scales.category.ticks.padding = 20;

    initializedCharts = true;
}

function getOrCreateTooltip(chart: Chart) {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (isNullable(tooltipEl)) {
        tooltipEl = document.createElement('div');
        tooltipEl.setAttribute('id', 'chart-tooltip');
        tooltipEl.setAttribute('role', 'tooltip');
        tooltipEl.classList.add('popover', 'bs-popover-top');

        const arrowEl = document.createElement('div');
        arrowEl.classList.add('popover-arrow', 'translate-middle-x');

        const contentEl = document.createElement('div');
        contentEl.classList.add('popover-content');

        tooltipEl.appendChild(arrowEl);
        tooltipEl.appendChild(contentEl);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
}

export function externalTooltipHandler(context: { chart: Chart; tooltip: TooltipModel<ChartType> }) {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.visibility = 'hidden';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const headEl = document.createElement('div');
        titleLines.forEach((title) => {
            const headingEl = document.createElement('h3');
            headingEl.classList.add('popover-header', 'text-center', 'text-nowrap');

            const text = document.createTextNode(title);

            headingEl.appendChild(text);
            headEl.appendChild(headingEl);
        });

        const bodyEl = document.createElement('div');
        bodyLines.forEach(([body], i) => {
            const colors = tooltip.labelColors[i];

            const indicatorEl = document.createElement('span');
            indicatorEl.classList.add('popover-body-indicator');
            indicatorEl.style.backgroundColor = (
                (chart.config as ChartConfiguration).type === 'line' && colors.borderColor !== 'rgba(0,0,0,0.1)'
                    ? colors.borderColor
                    : colors.backgroundColor
            ) as string; // We cannot use CanvasGradient and CanvasPattern here since we are in HTML

            const contentEl = document.createElement('div');
            contentEl.classList.add('popover-body', 'd-flex', 'align-items-center', 'text-nowrap');
            contentEl.classList.add(bodyLines.length > 1 ? 'justify-content-left' : 'justify-content-center');

            const text = document.createTextNode(body);

            contentEl.appendChild(indicatorEl);
            contentEl.appendChild(text);
            bodyEl.appendChild(contentEl);
        });

        const rootEl = tooltipEl.querySelector('.popover-content') as Element;

        // Remove old children
        while (rootEl.firstChild) {
            rootEl.firstChild.remove();
        }

        // Add new children
        rootEl.appendChild(headEl);
        rootEl.appendChild(bodyEl);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.transform = 'translateX(-50%) translateY(-100%) translateY(-1rem)';
}

/**
 * Only render the label if there are multiple datasets that need to be distinguished.
 */
function externalTooltipLabelCallback(this: TooltipModel<ChartType>, tooltipItem: TooltipItem<ChartType>) {
    const callbacks = tooltipItem.chart.options.plugins?.tooltip?.callbacks;
    const before = callbacks?.beforeLabel?.call(this, tooltipItem) ?? '';
    const after = callbacks?.afterLabel?.call(this, tooltipItem) ?? '';

    const scale = tooltipItem.chart.scales[tooltipItem.dataset.yAxisID || 'y'] as Scale<CartesianScaleOptions>;
    if (isNonNullable(scale)) {
        const label =
            (tooltipItem.chart.tooltip?.dataPoints.length ?? 0) > 1 ? ' ' + tooltipItem.dataset.label + ': ' : ' ';
        return label + before + scale.options.ticks.callback.apply(scale, [tooltipItem.parsed.y, 0, []]) + after;
    } else {
        return before + tooltipItem.formattedValue + after;
    }
}
