import Logger, { useDefaults, setHandler, createDefaultHandler } from 'js-logger';

useDefaults({
    defaultLevel: process.env.NODE_ENV === 'development' ? Logger.DEBUG : Logger.WARN,
    formatter(messages) {
        // prefix each log message with a timestamp.
        messages.unshift(new Date().toUTCString());
    },
});

setHandler(createDefaultHandler());
