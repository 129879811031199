import { supabaseClient } from '@/services/supabase';
import type { AuthUser, UserRole } from '../types/auth';
import { FrontendError, maybeThrowError } from '@/utils/error-helpers';
import { EdgeFunctionsManager } from '@/utils/edge-functions-manager';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import posthog from 'posthog-js';
import { currentLanguage } from '@/i18n';

export async function fetchUser(): Promise<AuthUser | undefined> {
    const { data } = await supabaseClient.auth.getUser();
    return data?.user as AuthUser | undefined;
}

export async function signInWithPassword(email: string, password: string): Promise<UserRole | undefined> {
    const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
    });
    maybeThrowError(error, 'errors.frontend.signInFailed');
    if (isNonNullable(data.user)) {
        const {
            id,
            email,
            app_metadata: { role },
        } = data.user;
        if (posthog.__loaded) {
            posthog.identify(id, { email, role });
        }
        return role;
    }
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
    await EdgeFunctionsManager.sendPasswordResetEmail(email, currentLanguage.value);
}

export async function updateEmail(newEmail: string): Promise<void> {
    const oldEmail = (await fetchUser())?.email;
    if (isNullable(oldEmail)) {
        throw new FrontendError('errors.frontend.currentEmailFetchFailed');
    }
    await EdgeFunctionsManager.changeEmail(oldEmail, newEmail, currentLanguage.value);
}

export async function updatePassword(password: string): Promise<void> {
    const { error } = await supabaseClient.auth.updateUser({ password });
    maybeThrowError(error, 'errors.frontend.passwordUpdateFailed');
}

export async function signOut(): Promise<void> {
    const { error } = await supabaseClient.auth.signOut();
    maybeThrowError(error, 'errors.frontend.signOutFailed');
}

export async function signInWithAccessCode(accessCode: string): Promise<string> {
    const { email, otp, simulationID } = await EdgeFunctionsManager.getParticipantSignInData(accessCode);
    await supabaseClient.auth.verifyOtp({
        email,
        token: otp,
        type: 'magiclink',
    });
    return simulationID;
}
