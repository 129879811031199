<template>
    <div class="fullscreen p-5 d-flex align-items-center overflow-auto">
        <div class="m-auto d-flex flex-column align-items-center">
            <img
                src="@/assets/img/illustrations/woman-pointing-finger.svg"
                alt="Woman with a laptop pointing finger to the side" />
            <h3 class="text-center mt-5">{{ t('mobileView.heyThere') }}<br />{{ t('mobileView.looksLikeMobile') }}</h3>
            <span class="text-center mt-2">
                {{ t('mobileView.mobileNotSupported') }}
            </span>
            <form action="https://sagacityintelligence.com/">
                <b-button variant="primary" type="submit" class="mt-4">{{ t('mobileView.backToHomepage') }}</b-button>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style scoped lang="scss">
.fullscreen {
    width: 100lvw;
    height: 100lvh;
    background-color: white;
}
</style>
