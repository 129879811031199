import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { isNonNullable } from '@/utils/nullability-helpers';
import type { Simulation } from '@/types/simulation';

export const selectedTeamKeyKey = 'sagacity-simulation:selected-team-key';
export const triggeredEventKeysKey = 'sagacity-simulation:triggered-events';
export const pastEventKeysKey = 'sagacity-simulation:past-events';

// Manages all the data that needs to be reset when the simulation changes
export function useSimulationStorage(simulation: Ref<Simulation | undefined>) {
    const simulationID = computed(() => simulation.value?.id);

    const lastSeenSimulationID = useStorage<string | undefined>('sagacity-simulation:last-simulation-id', undefined);

    watch(
        simulationID,
        () => {
            if (isNonNullable(simulationID.value) && simulationID.value !== lastSeenSimulationID.value) {
                localStorage.removeItem(selectedTeamKeyKey);
                localStorage.removeItem(triggeredEventKeysKey);
                localStorage.removeItem(pastEventKeysKey);
                lastSeenSimulationID.value = simulationID.value;
            }
        },
        { immediate: true },
    );
}
