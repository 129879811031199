import { computed, ref } from 'vue';
import type { AuthUser } from '../types/auth';
import { supabaseClient } from '@/services/supabase';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import { fetchUser } from '@/services/auth-service';
import { createGlobalState } from '@vueuse/core';

export const useAuthStore = createGlobalState(() => {
    const user = ref<AuthUser | undefined>(undefined);
    const isFetchingUser = ref(false);

    async function fetchUserIfNecessary(): Promise<AuthUser | undefined> {
        if (isNullable(user.value)) {
            isFetchingUser.value = true;
            user.value = await fetchUser();
            isFetchingUser.value = false;
        }
        return user.value;
    }

    async function refreshUser(): Promise<AuthUser | undefined> {
        user.value = await fetchUser();
        return user.value;
    }

    supabaseClient.auth.onAuthStateChange((_, session) => {
        user.value = session?.user as AuthUser | undefined;
    });

    const isLoggedIn = computed(() => isNonNullable(user.value));
    const isAdmin = computed(() => user.value?.app_metadata.role === 'admin');
    const isInstructor = computed(() => ['instructor', 'admin'].includes(user.value?.app_metadata.role ?? ''));

    return {
        user,
        isInstructor,
        isAdmin,
        isLoggedIn,
        fetchUserIfNecessary,
        refreshUser,
        isFetchingUser,
    };
});
