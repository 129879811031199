<template>
    <b-modal :model-value="showBanner" hide-header hide-footer @hide.prevent>
        <h1 class="mb-4" data-cy="cookie-modal-header">
            {{ t('cookieNotice.title') }}
        </h1>
        <p class="mb-5">{{ t('cookieNotice.description') }}</p>
        <div class="d-flex justify-content-between">
            <b-button class="btn-white me-3" data-cy="cookie-modal-decline" @click="declineCookies">
                {{ t('cookieNotice.decline') }}
            </b-button>
            <b-button variant="primary" data-cy="cookie-modal-accept" @click="acceptCookies">
                {{ t('cookieNotice.accept') }}
            </b-button>
        </div>
    </b-modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { posthog } from 'posthog-js';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const showBanner = ref(posthog.__loaded && !posthog.has_opted_in_capturing() && !posthog.has_opted_out_capturing());

function acceptCookies() {
    posthog.opt_in_capturing();
    showBanner.value = false;
}

function declineCookies() {
    posthog.opt_out_capturing();
    showBanner.value = false;
}
</script>
