export default {
    en: {
        loadingDots: 'Loading ...',
        back: 'Back',
        start: 'Start',
        continue: 'Continue',
        edit: 'Edit',
        delete: 'Delete',
        confirm: 'Confirm',
        cancel: 'Cancel',
        resend: 'Resend',
        search: 'Search',
        send: 'Send',
        update: 'Update',
        submit: 'Submit',
        next: 'Next',
        finish: 'Finish',
        close: 'Close',
        save: 'Save',
        sucessNotificationTitle: 'Success!',
        warningNotificationTitle: 'Warning!',
        errorNotificationTitle: 'Error!',
        help: 'Help',
        you: 'You',
        loadingMessages: ['Loading ...', 'Getting things ready for you ...', 'Piecing things together ...'],
        app: {
            navigation: {
                simulations: 'Simulations',
                competencies: 'Competencies',
                challenges: 'Challenges',
                courses: 'Courses',
                assessments: 'Assessments',
                users: 'Users',
                editAccount: 'Edit account',
                signOut: 'Sign out',
                toggleNavigation: 'Toggle navigation',
                displaySettings: 'Display settings',
            },
            simulations: {
                pageSubtitle: 'Participate in simulations to assess your competencies.',
                noSimulations: 'No simulations available',
                startSimulation: 'Start simulation',
            },
            competencies: {
                pageSubtitle: 'See your progress across different competencies.',
                overview: 'Overview',
                strengthAreas: 'Areas of strength',
                improvementAreas: 'Areas for improvement',
                yourCompletedAssessments: 'Your completed assessments',
                noAssessments: 'No assessments available',
                viewReport: 'View report',
                showAll: 'Show all',
                showLess: 'Show less',
            },
            challenges: {
                pageSubtitle: 'Solve the challenges and improve your skills.',
                noChallenges: 'No challenges available',
                explanation: 'Explanation',
                week: 'Week',
                new: 'New',
                completed: 'Completed',
                doneCounter: '{done} / {total} done',
            },
            courses: {
                assignedCourses: 'Assigned courses',
                passed: 'Passed',
                failed: 'Failed',
                completed: 'Completed',
                retake: 'Retake',
                retakeIn: 'Retake in {time}',
                startTask: 'Start task',
                resume: 'Resume',
                viewTasks: 'View tasks',
                tasksCompleted: '{done} / {total} tasks completed',
            },
        },
        admin: {
            simulationsManagement: {
                openSimulation: 'open simulation | open simulations',
                archivedSimulation: 'archived simulation | archived simulations',
                description: 'Manage active and archived simulations and create new ones.',
                simulationDefaultName: 'Simulation {nextSimulationNumber}',
                createSimulation: 'Create simulation',
                setUpSimulation: 'Set up a simulation',
                setUpSimulationSubtitle: 'Set up the name, start and end date of the simulation.',
                setUpTeams: 'Set up the teams',
                setUpTeamsSubtitle: 'Add the emails of the team members you want to invite to the simulation.',
                editSimulation: 'Edit simulation',
                editSimulationSubtitle: 'Edit the name, start and end date of the simulation.',
                editTeams: 'Edit teams',
                editTeamsSubtitle: 'Add the emails of the team members you want to invite to the simulation.',
                open: 'Open',
                archived: 'Archived',
                searchByName: 'Search by name',
                name: 'Name',
                enterName: 'Enter a name for the simulation',
                scenario: 'Scenario',
                loadingScenarios: 'Loading scenarios ...',
                selectScenario: 'Select a scenario',
                scenarioTeamsWarning: 'Warning: Changing the scenario will remove all users from the teams.',
                contentVariation: 'Content variation',
                defaultContent: 'Default',
                startDate: 'Start date',
                startDateDescription: 'The day when the simulation instance can be started.',
                startDateCannotBeEditedAfterStart: 'The start date can not be edited after the simulation has started.',
                numberOfTeamsCannotBeEditedAfterStart:
                    'The number of teams can not be edited after the simulation has started.',
                scenarioCannotBeEditedAfterStart: 'The scenario can not be edited after the simulation has started.',
                simulationCreated: 'The simulation has been created successfully.',
                simulationUpdated: 'The simulation has been updated successfully.',
                endDate: 'End date',
                endDateDescription: 'The day by the end of which the simulation instance should end.',
                enterMemberEmails: 'Enter member email addresses (Optional)',
                invalidEmailEntered: 'An invalid email address was entered.',
                emailAlreadyInUse: 'This email address is already used in team {team}.',
                addTeam: 'Add team',
                atLeastOneTeamRequired: 'At least one team is required.',
                scenarioNotLoadedYet: 'The scenario is not loaded yet.',
                saveWithoutSendingInvitations: 'Save without sending invitations',
                saveAndSendInvitations: 'Save and send invitations',
                saveSimulation: 'Save simulation',
                status: 'Status',
                activeStatus: 'Active',
                scheduledStatus: 'Scheduled',
                concludedStatus: 'Concluded',
                deleteSimulations: 'Delete simulations',
                confirmDeleteSimulations:
                    'Are you sure you want to delete this simulation? | Are you sure you want to delete these {count} simulations?',
                teamName: 'Team name',
                role: 'Role',
                accessCode: 'Access code',
                sendInvitations: 'Send invitations',
                resendInvitations: 'Resend invitations',
                allMembersInvited: 'All members have already been invited.',
                sendOnlyToUninvited: 'Send only to the participants that have not yet received an invitation',
                sendToAll: 'Send to all participants',
                invitationsWillBeSentTo: 'The invitation emails will be sent to:',
                invitationSentToAll: 'The invitation emails have been sent to all participants.',
                invitationSentToUninvited:
                    'The invitation emails have been sent to the participants that have not yet received an invitation.',
                invitationCouldNotBeSent: 'The invitation emails could not be sent.',
                sendReports: 'Send reports',
                resendReports: 'Resend reports',
                sendingReportsDots: 'Sending reports ...',
                reportsHaveBeenSent: 'Reports have been sent to the participants.',
                reportsHaveAlreadyBeenSent:
                    'Reports have already been sent out to all participants. Are you sure that you want to send them again?',
                noTeamMembersToSendReportsTo: 'There are no team members to send report emails to.',
                cannotSendReportsForScheduled: 'You can not send emails for scheduled simulations.',
                downloadReports: 'Download reports',
                generatingReportsDots: 'Generating reports ...',
                cannotDownloadReportsForScheduled: 'You can not download reports for scheduled simulations.',
                noTeamMembersToSendInvitationsTo: 'There are no team members to send invitation emails to.',
                cannotSendInvitationsForConcluded: 'You can not send invitation emails for concluded simulations.',
                archive: 'Archive',
                restore: 'Restore',
                viewAccessCodes: 'View access codes',
            },
            challengesManagement: {
                challenge: 'challenge | challenges',
                description: 'Generate and manage the continuous challenges that the learners have to face.',
                content: 'Content',
                files: 'Files',
                dropFiles: 'Drop PDF files here or click to upload',
                upload: 'Upload',
                integrations: 'Integrations',
                searchByText: 'Search by text',
                text: 'Text',
                status: 'Status',
                activeStatus: 'Active',
                unverifiedStatus: 'Unverified',
                answers: 'Answers',
                answersCount: 'answer | answers',
                createdAt: 'Created at',
                generate: 'Generate',
                generateChallenges: 'Generate challenges',
                verify: 'Verify',
                unverify: 'Unverify',
                contentFile: 'Content file',
                noContentFiles: 'No content files',
                selectContentFiles: 'Select content file',
                generatingChallenges: 'Generating challenges ...',
                challengesGenerated: 'Challenges successfully generated!',
                competencies: 'Competencies',
                noCompetencies: 'No competencies',
                difficulty: 'Difficulty',
                veryEasy: 'Very easy',
                easy: 'Easy',
                medium: 'Medium',
                hard: 'Hard',
                veryHard: 'Very hard',
                editChallenge: 'Edit challenge',
                enterChallengeText: 'Enter the text of the challenge',
                enterCompetencies: 'Enter the competencies of the challenge',
                atLeastOneAnswer: 'At least one answer is required.',
                atLeastOneCorrectAnswer: 'At least one correct answer is required.',
                answerOptions: 'Answer options',
                option: 'Option',
                isCorrect: 'Correct',
                addAnswer: 'Add answer',
            },
            usersManagement: {
                user: 'user | users',
                description: 'Maintain the list of participants that can use the system.',
                descriptionAdmin: 'Maintain the list of participants, instructors, and admins that can use the system.',
                participants: 'Participants',
                instructorsAndAdmins: 'Instructors & Admins',
                addUser: 'Add user',
                enterEmailToInvite: 'Enter email address to invite',
                emailAlreadyInUse: 'This email address is already in use.',
                inviteSent: 'Invite sent',
                editUser: 'Edit user',
                enterUserFirstName: 'Enter the first name of the user',
                enterUserLastName: 'Enter the last name of the user',
                rolesExplanation:
                    'Only admins can add instructors to the simulation. Both admins and instructors can create simulations, invite participants, and manage and run the simulations.',
                searchByNameOrEmail: 'Search by name or email',
                role: 'Role',
                adminRole: 'Admin',
                instructorRole: 'Instructor',
                participantRole: 'Participant',
                inviteLinkExpired: 'Invite link expired. Resend?',
                resendInviteLink: 'Resend invite link',
                resendingInviteLink: 'Resending invite link ...',
                inviteResent: 'Invite resent',
                confirmationEmailSent:
                    'A confirmation link has been sent to the user. The link will expire after 24 hours.',
                inviteResendFailedTitle: 'Failed to resend invite',
                inviteFailedDescription: 'The invite link could not be sent to the user.',
                deleteUsers: 'Delete users',
                confirmDeleteUsers:
                    'Unused | Are you sure you want to delete this user? | Are you sure you want to delete these {count} users?',
            },
            settings: {
                title: 'Settings',
                account: 'Account',
                general: 'General',
                security: 'Security',
                updateAccount: 'Update account',
                waitingForConfirmation: 'Waiting for confirmation',
                cannotChangeEmailUntilRequestFulfilled:
                    'Your email address can not be changed back to your original email until the current change request is fulfilled or it expires.',
                afterUpdateReceiveEmail:
                    'After updating your email address, you will receive an email to confirm the change.',
                accountHasBeenUpdated: 'Your account has been updated.',
                passwordHasBeenUpdated: 'Your password has been updated.',
                emailHasBeenChanged: 'Your email address has been changed.',
                pleaseCheckEmail: 'Please check your email to confirm the change of your email address.',
            },
        },
        auth: {
            login: 'Login',
            signInWithEmail: 'Sign in with your email address and password',
            emailAddress: 'Email address',
            emailAddressRequired: 'Email address*',
            enterEmail: 'Enter your email address',
            password: 'Password',
            enterPassword: 'Enter your password',
            forgotPassword: 'Forgot password?',
            forgotPasswordCap: 'Forgot Password?',
            signIn: 'Sign in',
            requestEmailToResetPassword: 'Request email to reset your password',
            passwordResetSuccessMessage:
                'We have sent you an email with instructions to reset your password. Check your inbox to continue or {0}!',
            rememberPassword: 'Remember your password?',
            signInHere: 'Sign in here',
            goBack: 'go back',
            resetPassword: 'Reset password',
            resetPasswordCap: 'Reset Password',
            chooseNewPassword: 'Choose a new password for your account',
            passwordChangedSuccessMessage: 'You have successfully changed your password. You can now {0}!',
            signInAgain: 'sign in again',
            setupAccount: 'Setup account',
            setupAccountCap: 'Setup Account',
            youHaveInvitation: 'You have been invited to create an account',
            firstName: 'First name',
            enterFirstName: 'Enter your first name',
            lastName: 'Last name',
            enterLastName: 'Enter your last name',
            newPassword: 'New password',
            enterNewPassword: 'Enter your new password',
            confirmNewPassword: 'Confirm new password',
            enterNewPasswordAgain: 'Enter your new password again',
            updatePassword: 'Update password',
            passwordRequirements: 'Password requirements',
            mustMeetPasswordRequirements:
                'To create a new password, you have to meet all of the following requirements:',
            passwordRequirementLength: 'At least 8 characters',
            passwordRequirementDigit: 'At least 1 digit',
            passwordRequirementSpecialCharacter: 'At least 1 special character',
            welcome: 'Welcome!',
            enterAccessCodeToSimulation: 'Enter the code to access the simulation',
            enterAccessCode: 'Enter the access code',
            haveAnAccount: 'Have an account?',
            weArePreparingSimulation: 'We are preparing your simulation',
        },
        simulation: {
            navigation: {
                collapse: 'Collapse',
                exitSimulation: 'Exit simulation',
            },
            presence: {
                adjectives: ['Fluffy', 'Happy', 'Mighty', 'Clever', 'Brave', 'Wise', 'Quiet', 'Smart', 'Friendly'],
                animals: ['Penguin', 'Dolphin', 'Elephant', 'Koala', 'Panda', 'Rabbit', 'Fox', 'Owl'],
            },
            defaultPeriodNaming: {
                singular: {
                    atSentenceStart: 'Period',
                    inSentence: 'period',
                },
                plural: {
                    atSentenceStart: 'Periods',
                    inSentence: 'periods',
                },
            },
            decisions: {
                submission: {
                    currentSubmission: 'Current submission',
                    previousSubmissions: 'Previous submissions',
                    title: 'Summary',
                    subtitle:
                        "Here's a recap of the decisions you have made in this {inSentence} and how they compare to the last {inSentence}!",
                    changes: '1 change | {count} changes',
                    editDecisions: 'Edit decisions',
                    confirmSubmission: 'Confirm submission',
                    successNotification: {
                        title: 'Submission successful!',
                        text: 'You have successfully submitted your decisions for this {inSentence}.',
                    },
                    invalidNotification: {
                        title: 'Submission not possible!',
                        text: 'There are some errors in your submission that you need to fix first.',
                    },
                    taskSubmissionError: 'An error occurred while submitting the task. Please try again.',
                    clickToSeeTaskDescription: 'Click to see the task description',
                },
                sliderDecision: {
                    level: 'Level {level}',
                },
                multiSelectDecision: {
                    subtitle: 'You can select up to 1 option | You can select up to {count} options',
                    summary: {
                        noOptionsSelected: 'No options selected',
                    },
                },
                chooseAndMapDecision: {
                    expand: 'Details',
                    collapse: 'Collapse',
                },
                conversationDecision: {
                    conversationTitle: 'Conversation',
                    emptyChatMessage:
                        'At each next step in the conversation, you will be able to see the conversation flow up to the last step here based on the responses you rank as 1 at each step.',
                    messageCount: 'Message {current}/{total}',
                },
                resourceAllocationDecisoin: {
                    availableBudget: 'Available budget: {budget}',
                    totalBudget: 'Total budget: {budget}',
                },
                selectWithStakeholderStanceDecision: {
                    stakeholderStance: 'Stakeholder stance',
                    stakeholderStanceOn: 'Stakeholder stance on {title}',
                },
                roleAssignmentDecision: {
                    jobDescription: 'Job description',
                    keyDeliverables: 'Key deliverables',
                    rankTeamMembersFromTo:
                        'Rank the team members listed below from 1 to {count} based on their suitability for the above role. Full team profiles are available in the Control Room.',
                    interestByTeamMembers: 'Interest by team members',
                },
                table: {
                    decisionColumnHeader: 'Decision',
                    submissionPending: 'Submission pending',
                },
                validation: {
                    pleaseFillOut: 'Please fill out this field.',
                    valueShouldBeBetween: 'The value should be between {min} and {max}.',
                },
                rank: 'Rank',
            },
            performance: {
                metrics: 'Metrics',
                dashboardMetricTable: {
                    allTeams: 'All teams',
                    percentageChangeColumnHeader: '% Change',
                    toggleRow: 'Toggle {title} row',
                    less: 'Less',
                },
                metricCard: {
                    tabModes: {
                        latest: 'Latest',
                        change: 'Change',
                        trends: 'Trends',
                        competitiors: 'Competitors',
                    },
                    availableAfterFirstPeriod: 'Available after the first {inSentence}',
                    analyze: 'Analyze',
                },
                strategicObjectives: {
                    dropDownPlaceholder: 'Strategic objective',
                    metrics: 'Strategic objective metrics',
                    searchAMetric: 'Search for a metric',
                    noMatchingMetrics: 'No matching metrics',
                    none: 'None',
                    pathColorHint: 'The green-highlighted cards below form a part of the selected objective',
                },
            },
            teamSelection: {
                instructorMode: 'Instructor mode',
                asTeam: 'As Team',
            },
            simulationManagementCard: {
                startPeriod: 'Start {inSentence}',
                calculatePeriod: 'Calculate {inSentence}',
                calculatingPeriod: 'Calculating ...',
                sendReports: 'Send reports',
                sendingReports: 'Sending ...',
                viewAccessCodes: 'View access codes',
                endSimulation: 'End simulation',
                simulationStatus: {
                    canBeStarted: '{atSentenceStart} ready to be started!',
                    allSubmitted: 'All teams have submitted their decisions',
                    nTeamsHaveSubmitted: '{submissionCount}/{teamCount} teams have submitted their decisions',
                    waitingForNextPeriod: 'Waiting for next {inSentence}',
                    decisionsPending: 'Your decisions are pending!',
                    investigateOutcomes: 'Investigate news and performance outcomes',
                },
                sendReportsNotificationText: 'Reports have been sent to the participants',
                timeOver: 'Time over!',
                timeLeft: 'Time left: {timeLeft}',
            },
            infoSlidesModal: {
                information: 'Information',
                duration: 'Duration: {duration}',
            },
            helpModal: {
                instructorHandbookTitle: "Instructor's Handbook",
                participantHandbookTitle: "Manager's Handbook",
            },
            newResultsNotification: {
                title: 'New results',
                text: '{atSentenceStart} results are now available.',
            },
            newDecisionsNotification: {
                title: 'New {inSentence} decisions',
                text: 'You can now add decisions for the new {inSentence}.',
            },
            assessments: {
                competenceAssessments: 'Competence Assessments',
                assessmentWillBeGenerated: 'Assessments will be generated once the simulation is completed.',
                loadingAssessments: 'Loading assessments ...',
                competency: 'Competency',
            },
        },
        fontSize: {
            groupTitle: 'Text size',
            0: 'Regular',
            1: 'Large',
            2: 'Very large',
        },
        language: {
            groupTitle: 'Language',
            en: 'English',
            ar: 'العربية',
        },
        theme: {
            groupTitle: 'Theme',
            auto: 'Auto',
            light: 'Light',
            dark: 'Dark',
        },
        errors: {
            error: 'Error',
            timeout: 'Timeout',
            unauthorized: 'Unauthorized',
            somethingWentWrong: 'Something went wrong!',
            pleaseTryAgainOrContactSupport: 'Please try again or contact support.',
            noPageHere: "There's no page here!",
            looksLikeYouEndedHereByAccident: 'Looks like you ended up here by accident?',
            returnToStart: 'Return to start',
            // Frontend error messages
            frontend: {
                general: 'Something went wrong!',
                simulationEndedAutomaticSignOut: 'The simulation has ended! You have been signed out automatically.',
                simulationFetchFailed: 'Could not get simulation!',
                simulationNotLoaded: 'The simulation is not loaded',
                simulationNotProvided: 'The simulation can not be accessed',
                signInFailed: 'Failed to sign in',
                signOutFailed: 'Failed to sign out',
                notSignedIn: 'You are not signed in',
                currentEmailFetchFailed: 'Could not get the current email address',
                passwordUpdateFailed: 'Failed to update password',
                scenariosFetchFailed: 'Could not get scenarios',
                simulationsFetchFailed: 'Could not get simulations',
                simulationCountFetchFailed: 'Could not get the number of simulations',
                simulationSaveFailed: 'Could not save simulation',
                simulationArchiveFailed: 'Could not archive simulation',
                simulationDeleteFailed: 'Could not delete simulation',
                simulationRestoreFailed: 'Could not restore simulation',
                teamFetchFailed: 'Could not get team',
                teamsFetchFailed: 'Could not get teams',
                teamsSaveFailed: 'Could not save teams',
                teamsDeleteFailed: 'Could not delete teams for simulation',
                decisionSubmitFailed: 'Could not submit decisions',
                simulationUserRoleFetchFailed: 'Could not get simulation user role',
                userSaveFailed: 'Could not save user',
                usersFetchFailed: 'Could not get users',
                usersDeleteFailed: 'Could not delete users',
                currentUserFetchFailed: 'Could not get current user',
                loadFilesFailed: 'Could not load files',
                uploadFileFailed: 'Could not upload file',
                deleteFileFailed: 'Could not delete file',
                challengesFetchFailed: 'Could not get challenges',
                challengeUpdateFailed: 'Could not update challenge',
                assessmentsFetchFailed: 'Could not get assessments',
                userNotLoggedIn: 'User is not logged in',
                userCourseAssignmentsFetchFailed: 'Could not get user course assignments',
                coursesFetchFailed: 'Could not get courses',
                userTaskAttemptFetchFailed: 'Could not get latest user task attempt',
            },
            edgeFunctions: {
                // Edge functions 4XX error messages
                userNotAdmin: 'User is not an admin. Only admins can perform this action.',
                userNotInstructor: 'User is not an instructor. Only instructors can perform this action.',
                userNotFound: 'Could not get user',
                simulationNotFound: 'Simulation not found',
                teamNotFound: 'Team not found',
                missingTeamDecisions: 'Not all teams submitted their decisions',
                invalidAccessCode: 'The access code is invalid',
                simulationEnded: 'The simulation has already ended',
                simulationNotStarted: 'The simulation has not started yet',
                userUnauthorizedChangeMail: 'User is not authorized to change email',
                requestMissingForm: 'Request is missing form',
                incompleteRequestData: 'Missing request data',
                userAlreadyExists: 'User already exists',
                periodAlreadyStarted: 'The period is already started',
                // Edge functions 5XX error messages
                couldNotProcess: 'An error occurred while processing your request',
                userCreateFailed: 'An error occurred while creating the user',
                roleSetFailed: 'Could not set role',
                passwordResetEmailSendFailed: 'An error occurred while sending password reset email',
                inviteEmailSendFailed: 'An error occurred while sending invite emails',
                emailChangeEmailFailed: 'An error occurred while sending email to confirm email change',
                reportEmailsSendFailed: 'An error occurred while sending report emails',
                scenarioDefinitionNotFound: 'Scenario definition not found',
                simulationNotFinished: 'Simulation is not finished',
                invalidJwt: 'There is something wrong with your access link. Please request a new one.',
                expiredJwt: 'Your access link has expired. Please request a new one.',
            },
        },
        validation: {
            mixed: {
                required: 'Please fill out this field.',
            },
            string: {
                email: 'Please enter a valid email address.',
                min: 'Please enter at least ${min} characters.',
                max: 'Please enter no more than ${max} characters.',
                length: 'Please enter exactly ${length} characters.',
                atLeastOneNumber: 'Please incorporate at least one number.',
                atLeastOneSpecialCharacter: 'Please incorporate at least one special character.',
                passwordsMatch: 'Passwords must match.',
            },
            boolean: {
                isValue: '${path} field must be ${value}',
                acceptTos: 'Please accept the terms of service and privacy policy.',
            },
        },
        table: {
            perPage: '{count} per page',
            filters: 'Filters',
            clearFilters: 'Clear filters',
            any: 'Any',
            noMatchesFound: 'No matching {item} found!',
            noItemsExist: 'There are no {item} yet!',
            noActionsDefined: 'No actions defined.',
            applyFilter: 'Apply filter | Apply filters',
        },
        wizard: {
            stepOf: 'Step {step} of {total}',
        },
        smartInstructor: {
            smartInstructor: 'Smart Instructor',
            resetChat: 'Reset chat',
            failedToSendRetry: 'Failed to send! Click here to retry',
            respondingDots: 'Responding ...',
            askMeAQuestionDots: 'Ask me a question ...',
            suggestions: 'Suggested questions',
            moreInsights: 'More insights',
            typeResponseDots: 'Type your response...',
        },
        cookieNotice: {
            title: 'Data Collection Consent',
            description:
                'We use essential digital markers and analytics tools to enhance functionality and improve our service. Your privacy is important to us. You have the option to accept these tools or reject them.',
            decline: 'Reject',
            accept: 'Accept',
        },
        demo: {
            finishDemo: 'Finish demo',
            auth: {
                title: 'Welcome!',
                subtitle: 'Submit the form below for an access link to our demo simulation',
                name: 'Name',
                enterName: 'Enter your name',
                organization: 'Organization',
                enterOrganization: 'Enter your organization',
                getAccessLink: 'Get access link',
                tosDescription1: 'I agree to the ',
                tosLink: 'terms of service',
                tosDescription2: ' and have read the ',
                privacyPolicyLink: 'privacy policy',
                checkInboxForAccessLink:
                    'We sent you an email with a link to access the demo. Please check your inbox.',
            },
            scenarioSelection: {
                welcomeToDemo: 'Welcome to our demo simulation!',
                description:
                    'Our simulations cover a wide range of functional areas and industries. This demo focuses on sales productivity.',
                greatExperience: 'We hope you have a great experience exploring it!',
                startSimulation: 'Start the simulation',
            },
            useCases: {
                enjoyedDemo: 'Enjoyed the demo? There is more!',
                wideRange: 'Our simulations cover a wide range of cuntional areas and industries.',
                someExamples: 'Here are some examples:',
                leadershipDevelopment: {
                    title: 'Leadership Development',
                    description: 'Build impactful leaders to manage complex challenges',
                },
                financialAcumen: {
                    title: 'Financial Acumen',
                    description: 'Empower managers to drive fiscal success',
                },
                businessManagement: {
                    title: 'Business Management',
                    description: 'Cultivate management talent for organizational growth',
                },
                salesProductivity: {
                    title: 'Sales Productivity',
                    description: 'Boost sales productivity and buid high performing sales team',
                },
                cashFlowManagement: {
                    title: 'Cash Flow Management',
                    description: 'Optimize liquidity management for sustainable growth',
                },
                riskManagement: {
                    title: 'Risk Management',
                    description: 'Build talent to place smart bets and mitigate risk across your company',
                },
                lookingForDifferentUseCase: 'Looking for a different use case?',
                canBeExtended: 'Our simulations can be extended to meet your specific requirements',
                getInTouch: 'Get in touch with us to transform you organization!',
                contactUs: 'Contact us',
            },
        },
        mobileView: {
            heyThere: 'Hey there!',
            looksLikeMobile: 'Looks like you are using a mobile device.',
            mobileNotSupported:
                'Our demo simulation is configured for a desktop based experience. Please use a desktop to access our demo page.',
            backToHomepage: 'Back to homepage',
        },
        cms: {
            selectActivityType: 'Select Activity Type',
            addTopic: 'Add Topic',
            addSubtopic: 'Add sub-topic',
            addSubtopicUnder: 'Add sub-topic under {topic}',
            editDetails: 'Edit details - {topic}',
            addActivity: 'Add activity',
            topicName: 'Name',
            topicDescription: 'Description',
            topicImage: 'Image',
            decisions: {
                general: {
                    content: 'Content',
                    evaluation: 'Evaluation',
                    tryPreview: 'Try out the preview!',
                    preview: 'Preview',
                    evaluationCriteria: 'Evaluation criteria',
                    evaluationCriteriaDescription:
                        "Here you can define the criteria that will be used to evaluate the user's performance. You can select from our pre-defined criteria or provide one of your own.",
                    competency: 'Competency',
                    competencyPlaceholder: 'Enter competency name or select from the list',
                    competencyAttribute: 'Attributes to evaluate for this competency',
                    competencyAttributePlaceholder: 'Enter attributes to evaluate for this competency',
                    addCompetency: 'Add another competency',
                    scoringScheme: 'Scoring scheme',
                    percentage: 'Percentage',
                    rating: 'Rating (e.g. 1-5)',
                    bestRating: 'Best rating',
                    optional: 'optional',
                },
                aiConversation: {
                    conversationTheme: 'Conversation theme',
                    conversationThemeMessage:
                        "Let's begin by selecting a theme for the conversation you would like to generate. You can select from from our pre-defined themes or provide one of your own by selecting 'Custom'. On selecting a theme, you will be able to provide further details for the conversation.",
                    closeConversationThemeMessage: 'Close message',
                    conversationThemeToolbar: 'Toolbar with conversation theme option buttons',
                    tryAnother: 'Try another profile',
                    specifyYourOwn: 'Or specify your own',
                    intraOffice: 'Intra-office',
                    customerSupport: 'Customer Support',
                    sales: 'Sales',
                    custom: 'Custom',
                    context: 'Context',
                    contextPlaceholder: 'What is the conversation about?',
                    rulesAndGuidelines: 'Rules & guidelines',
                    rulesAndGuidelinesPlaceholder: 'Provide a list of rules to be followed during the conversation',
                    profile: 'Profile',
                    profilePlaceholder:
                        'What should be the name, designation and other characteristics of the virtual employee played by the AI?',
                    avatar: 'Avatar',
                    scenario: 'Scenario',
                    scenarioPlaceholder:
                        'Describe the specific situation to be addressed, e.g. the task that the virtual employee needs to perform, the conflict that needs to be resolved involving the virtual employee etc.',
                },
            },
        },
    },
};
