import { isNullable } from '@/utils/nullability-helpers';
import type { LocalizedString } from '@shared-types/scenario-definition';
import { currentLanguage } from '.';

export function st(localizedString: LocalizedString): string;
export function st(localizedString?: LocalizedString): string | undefined;

export function st(localizedString?: LocalizedString) {
    if (isNullable(localizedString)) {
        return undefined;
    }
    if (typeof localizedString === 'string') {
        return localizedString;
    }
    if ('_tKey' in localizedString) {
        console.warn('Unresolved translation key:', localizedString._tKey);
        return localizedString._tKey;
    }
    if ('_tKeyResolved' in localizedString) {
        // We read the value from currentLanguage to force reactive dependency so that the value is updated
        // when the language changes wherever this function is used in a reactive context.
        currentLanguage.value;
        return localizedString._tKeyResolved;
    }
    return localizedString.localization[currentLanguage.value];
}

export function maybeSt(localizedString: LocalizedString | string): string;
export function maybeSt(localizedString?: LocalizedString | string): string | undefined;

export function maybeSt(localizedString?: LocalizedString | string) {
    if (isNullable(localizedString)) {
        return undefined;
    }
    if (typeof localizedString === 'string') {
        return localizedString;
    }
    return st(localizedString);
}

declare module 'vue' {
    interface ComponentCustomProperties {
        $st: typeof st;
        $maybeSt: typeof maybeSt;
    }
}
