import type { ElementType } from '../core/types/editor-types';

export const componentElementTypes = [
    'component-decision',
    'component-row',
    'component-tasks-page',
] as const satisfies ElementType[];
export type ComponentElementType = (typeof componentElementTypes)[number];
export const decisionElementTypes = [
    'rank-decision',
    'assignment-decision',
    'map-decision',
    'select-stakeholder-message-decision',
    'rank-table-decision',
    'select-with-stakeholder-stance-decision',
    'conversation-decision',
    'resource-allocation-decision',
    'numeric-decision',
    'quiz-decision',
] as const satisfies ElementType[];
export type DecisionElementType = (typeof decisionElementTypes)[number];
