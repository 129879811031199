<template>
    <MobileView v-if="isMobile" />

    <router-view />
    <notifications :position="isRtl ? 'top left' : 'top right'" dangerously-set-inner-html />
    <CookieModal />

    <div v-if="isFetchingUser" class="loading-overlay bg-light">
        <LoadingIndicator />
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { isRtl } from './i18n';
import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

const { isFetchingUser } = useAuthStore();

const { width, height } = useWindowSize();

// The widest viewport I could find is the iPhone 15 Pro Max with 430px.
const isMobile = computed(() => width.value < 435 || height.value < 435);
</script>

<style scoped lang="scss">
.loading-overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1054;
    opacity: 0.85;
}
</style>
