import { createI18n } from 'vue-i18n';
import messages from './lang/en';
import { Settings } from 'luxon';
import { useStorage } from '@vueuse/core';
import { setLocale } from 'yup';
import { isNonNullable } from '@/utils/nullability-helpers';
import { computed } from 'vue';
import type { PossibleLanguage } from '@shared-types/scenario-definition';

export type { PossibleLanguage } from '@shared-types/scenario-definition';

const fallbackLanguage: PossibleLanguage = 'en';

export const possibleLanguages: PossibleLanguage[] = [fallbackLanguage, 'ar'];

const rtlLanguages: PossibleLanguage[] = ['ar'];

const i18n = createI18n({
    legacy: false,
    locale: fallbackLanguage, // set locale
    fallbackLocale: fallbackLanguage,
    messages, // set locale messages
    silentTranslationWarn: import.meta.env.PROD,
    warnHtmlMessage: false,
});

function isPossibleLanguage(lang: string): lang is PossibleLanguage {
    return possibleLanguages.includes(lang as PossibleLanguage);
}

export type MessageSchema = (typeof messages)[typeof fallbackLanguage];

export type TranslationKey = FlattenKeys<MessageSchema>;

type FlattenKeys<T> = T extends object
    ? { [K in keyof T]: T[K] extends object ? `${K & string}.${FlattenKeys<T[K]> & string}` : K }[keyof T]
    : '';

export const currentLanguage = useStorage<PossibleLanguage>(
    'sagacity-simulation:language',
    getFirstSupportedOfLanguages(possibleLanguages),
);

export const isRtl = computed(() => rtlLanguages.includes(currentLanguage.value));

/**
 * Makes sure the language from the local storage is valid. Otherwise, resets it to the browser language or the
 * fallback language.
 * Then, it loads the language asynchronously.
 */
export async function initializeLanguage() {
    if (!isPossibleLanguage(currentLanguage.value)) {
        // If the language is set to the fallback language (en), loadLanguageAsync will not set the language in the local
        // storage. Therefore we set it manually here.
        currentLanguage.value = getFirstSupportedOfLanguages(possibleLanguages);
        return;
    }

    if (currentLanguage.value === fallbackLanguage) {
        setI18nLanguage(fallbackLanguage);
    } else {
        await loadLanguageAsync(currentLanguage.value);
    }
}

const loadedLanguages: string[] = [fallbackLanguage]; // our default language that is preloaded

export async function loadLanguageAsync(lang: string): Promise<void> {
    if (i18n.global.locale.value !== lang && isPossibleLanguage(lang)) {
        if (!loadedLanguages.includes(lang)) {
            const msgs = await import(`./lang/${lang}.ts`);
            i18n.global.setLocaleMessage(lang, msgs.default[lang]);
            loadedLanguages.push(lang);
            setI18nLanguage(lang);
        }
        setI18nLanguage(lang);
    }
}

function setI18nLanguage(lang: PossibleLanguage) {
    // @ts-expect-error we have to wait until vue-i18n fixes the types http!s://github.com/intlify/vue-i18n-next/issues/1003
    i18n.global.locale.value = lang;
    currentLanguage.value = lang;
    document.documentElement.setAttribute('lang', lang);
    document.documentElement.setAttribute('dir', rtlLanguages.includes(lang) ? 'rtl' : 'ltr');
    Settings.defaultLocale = lang;
    const validationMessages = (i18n.global.messages.value as Record<PossibleLanguage, MessageSchema>)[lang].validation;
    if (isNonNullable(validationMessages)) {
        setLocale((i18n.global.messages.value as Record<PossibleLanguage, MessageSchema>)[lang].validation);
    }
    return lang;
}

/**
 * Returns the first language from the browsers configured languages which is part of `languages`.
 * If no supported language is found in the browser, the first language is returned.
 */
export function getFirstSupportedOfLanguages<T extends string>(languages: T[]): T {
    for (const languageCode of navigator.languages) {
        const language = languageCode.split('-')[0] as T;
        if (languages.includes(language)) {
            return language;
        }
    }
    return languages[0];
}

export default i18n;
