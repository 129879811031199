import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { useAuthStore } from '@/stores/auth';
import posthog from 'posthog-js';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import { nextTick } from 'vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
});

// Global navigation guard
// - Only authenticated users can access simulation area
// - Only authenticated instructors and admins can access admin area
// - If the route is specified to be admin only, disallow direct access to the route
router.beforeEach(async (to) => {
    const { fetchUserIfNecessary } = useAuthStore();
    // We do not need the user to be reactive
    const user = await fetchUserIfNecessary();

    if (to.hash.length > 0) {
        const hashQueryParams = parseQuery(to.hash);
        if (isNonNullable(hashQueryParams.error)) {
            return {
                name: 'error',
                query: hashQueryParams,
            };
        }
    }

    if (to.meta.noAuth) {
        return true;
    } else if (isNullable(user) || isNullable(user.app_metadata.role)) {
        return {
            name: to.meta.redirectOnMissingAuth ?? 'login',
            query: { redirect: to.query.redirect ?? to.fullPath },
        };
    }

    const role = user.app_metadata.role;

    if (to.path.startsWith('/app') && role !== 'admin') {
        return { path: '/mte' };
    }

    if (isNonNullable(to.meta.requiresRole) && !to.meta.requiresRole.includes(role)) {
        // Redirect to basic view if entered the app directly or changed the URL. Otherwise, prevent navigation.
        return { path: role === 'instructor' || role === 'admin' ? '/admin' : '/app' };
    } else if (role === 'instructor' && to.path.startsWith('/app')) {
        return { path: '/admin' };
    }
});

/**
 * Parses a supabase query string into an object. The hash query string starts with # but is otherwise built like regular query strings
 * @param hashQueryString
 */
function parseQuery(hashQueryString: string): Record<string, string> {
    const query: Record<string, string> = {};
    const pairs = hashQueryString.substring(1).replace(/\+/g, '%20').split('&');
    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        query[decodeURIComponent(key)] = decodeURIComponent(value ?? '');
    }
    return query;
}

// Capture pageviews with PostHog
router.afterEach((to) => {
    if (posthog.__loaded) {
        nextTick(() => {
            posthog.capture('$pageview', {
                $current_url: to.fullPath,
            });
        });
    }
});

export default router;
