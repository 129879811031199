import type { TranslationKey } from '@/i18n';
import { isNonNullable } from '@/utils/nullability-helpers';
import { ref, type Ref, watch } from 'vue';

export function useErrorAlert<T extends string | TranslationKey = TranslationKey>(errorOverride?: Ref<T | undefined>) {
    const error = errorOverride ?? ref<T | undefined>(undefined);
    const showErrorAlert = ref(isNonNullable(error.value));

    watch(error, (newError) => {
        showErrorAlert.value = isNonNullable(newError);
    });

    return { error, showErrorAlert };
}
