<template>
    <div class="min-vh-100 d-flex align-items-center" :class="props.class">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
                    <div class="text-center">
                        <img
                            src="@/assets/img/illustrations/woman-pointing-finger.svg"
                            alt="Confused person in front of a laptop"
                            class="img-fluid" />
                    </div>
                </div>
                <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                    <div class="text-center">
                        <h4 class="text-uppercase text-muted mb-4">
                            {{ te(props.supertitle) ? t(props.supertitle) : props.supertitle }}
                        </h4>

                        <h1 class="display-4 mb-3">{{ t(props.title) }}</h1>

                        <p class="text-muted mb-5 pt-1">
                            {{ te(props.subtitle) ? t(props.subtitle) : props.subtitle }}
                        </p>

                        <b-button variant="primary" @click="returnToStart">{{ t('errors.returnToStart') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <img src="@/assets/img/logos/logo-blue-dark-with-subtext.svg" alt="Sagacity Logo" class="logo" /> -->
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import type { TranslationKey } from '@/i18n';
import { useI18n } from 'vue-i18n';

interface ErrorLayoutProps {
    supertitle: TranslationKey | string;
    title: TranslationKey;
    subtitle: TranslationKey | string;
    class?: string;
}

const props = defineProps<ErrorLayoutProps>();

const { t, te } = useI18n();

const router = useRouter();
const { fetchUserIfNecessary } = useAuthStore();

async function returnToStart() {
    const user = await fetchUserIfNecessary();
    const userRole = user?.app_metadata.role;
    if (userRole === 'instructor' || userRole === 'admin') {
        router.push('/admin');
    } else {
        router.push('/');
    }
}
</script>

<style scoped lang="scss">
.logo {
    position: absolute;
    width: 100px;
    bottom: 20px;
    left: 36px;
}
</style>
