import { supabaseClient } from '@/services/supabase';
import { FunctionsHttpError } from '@supabase/supabase-js';
import { isNonNullable } from './nullability-helpers';
import type { PossibleLanguage, TranslationKey } from '@/i18n';
import { FrontendError } from './error-helpers';
import type { FrontendScenarioDefinition } from '@shared-types/frontend-scenario-definition';
import type { PeriodKey, PublicScenarioDetails } from '@shared-types/scenario-definition';
import type { FrontendSimulationMetadata } from '@/types/simulation';
import type { CompetencyFramework } from '@shared-types/competencies';

export class EdgeFunctionsManager {
    static getParticipantSignInData = (
        accessCode: string,
    ): Promise<{ email: string; otp: string; simulationID: string }> =>
        this.invokeEdgeFunction('get-participant-sign-in-data', { accessCode });

    static createUserInvite = (email: string, role: string, language: PossibleLanguage): Promise<void> =>
        this.invokeEdgeFunction('create-user-invite', { email, role, language });

    static resendInviteLink = (id: string, language: PossibleLanguage): Promise<void> =>
        this.invokeEdgeFunction('resend-invite-link', { id, language });

    static requestAccessLink = (
        email: string,
        language: PossibleLanguage,
        name?: string,
        organization?: string,
    ): Promise<string> => this.invokeEdgeFunction('request-access-link', { email, name, organization, language });

    static createDemoSimulation = async (): Promise<{ id: string }> =>
        this.invokeEdgeFunction('create-demo-simulation');

    static createSimulation = async (simulationMetadata: FrontendSimulationMetadata): Promise<{ id: string }> =>
        this.invokeEdgeFunction('create-simulation', { simulationMetadata });

    static editSimulation = async (
        simulationID: string,
        simulationMetadata: FrontendSimulationMetadata,
    ): Promise<void> => this.invokeEdgeFunction('edit-simulation', { simulationID, simulationMetadata });

    static calculateNextPeriod = (simulationID: string, currentPeriodKey: PeriodKey): Promise<void> =>
        this.invokeEdgeFunction('calculate-next-period', { simulationID, currentPeriodKey });

    static assessSimulation = (simulationID: string): Promise<void> =>
        this.invokeEdgeFunction('assess-simulation', { simulationID });

    static startPeriod = (simulationID: string): Promise<void> =>
        this.invokeEdgeFunction('start-period', { simulationID });

    static sendParticipantInviteEmails = async (simulationID: string, language: PossibleLanguage, sendToAll: boolean) =>
        this.invokeEdgeFunction('send-invite-emails', { simulationID, sendToAll, language });

    static sendReportsEmailToTeam = (formData: FormData) =>
        this.invokeEdgeFunction('send-reports-email-to-team', formData);

    static sendPasswordResetEmail = (email: string, language: PossibleLanguage): Promise<void> =>
        this.invokeEdgeFunction('send-reset-password-email', { email, language });

    static changeEmail = (email: string, newEmail: string, language: PossibleLanguage): Promise<void> =>
        this.invokeEdgeFunction('send-change-email-email', { email, newEmail, language });

    static createSimulationKnowledge = (simulationID: string): Promise<void> =>
        this.invokeEdgeFunction('create-simulation-knowledge', { simulationID });

    static getPossibleScenarios = (): Promise<PublicScenarioDetails[]> =>
        this.invokeEdgeFunction('get-possible-scenarios');

    static getScenarioDefinition = (
        body: { simulationID: string } | { scenarioID: string },
    ): Promise<FrontendScenarioDefinition> => this.invokeEdgeFunction('get-scenario-definition', body);

    static generateAIConversation = (body: { messages: any[] }): Promise<any> =>
        this.invokeEdgeFunction('generate-ai-conversation', body);

    static getScenarioTranslation = (body: { scenarioID: string; language: string }): Promise<any> =>
        this.invokeEdgeFunction('get-scenario-translation', body);

    static getCompetencyFramework = (frameworkId: string): Promise<CompetencyFramework> =>
        this.invokeEdgeFunction('get-competency-framework', { frameworkId });

    static generateChallenges = (contentFile: string): Promise<void> =>
        this.invokeEdgeFunction('generate-challenges', { contentFile });

    static verifyJwt = (jwt: string): Promise<{ token: string; email: string; simulationId: string }> =>
        this.invokeEdgeFunction('verify-jwt', { jwt });

    static startTask = (courseId: string): Promise<void> => this.invokeEdgeFunction('start-task', { courseId });

    private static invokeEdgeFunction = async (name: string, body?: Record<string, any>) => {
        const { data, error } = await supabaseClient.functions.invoke(name, { body });
        if (isNonNullable(error)) {
            if (error instanceof FunctionsHttpError) {
                const {
                    error: { code },
                } = (await error.context.json()) as { error: { code: string } };
                throw new FrontendError(`errors.edgeFunctions.${code}` as TranslationKey);
            } else {
                throw error;
            }
        }
        return data;
    };
}
