import { computed, type Ref, watch, ref } from 'vue';
import type { Simulation } from '@/types/simulation';
import { isNullable } from '@/utils/nullability-helpers';
import {
    getSimulationResults,
    getSimulationDecisions,
    listenToSimulation,
    listenToSimulationResults,
    listenToSimulationDecisions,
    getTeamsWithUsers,
    getSimulation,
} from '@/services/simulation-service';
import { DateTime } from 'luxon';
import { useAsyncState } from '@vueuse/core';
import { createInjectionState } from '@vueuse/core';
import { FrontendError } from '@/utils/error-helpers';
import { supabaseClient } from '@/services/supabase';
import { useSimulationStorage } from './useSimulationStorage';
import type { FrontendScenarioDefinition } from '@shared-types/frontend-scenario-definition';
import { EdgeFunctionsManager } from '@/utils/edge-functions-manager';
import { useElementStructure } from '@/editor/core/use-element-structure';
import { currentLanguage } from '@/i18n';
import { decisionElementTypes } from '@/editor/scenario-editor/element-groups';

// Only use this in areas of the simulation view that are shown if the simulation is loaded.
export const [provideSimulationInstanceStore, useSimulationInstanceStore] = createInjectionState(
    (simulationID: Ref<string>) => {
        watch(currentLanguage, (newLanguage) => {
            setLanguage(newLanguage);
        });

        const { addElements, getElementsByType, setLanguage } = useElementStructure();
        setLanguage(currentLanguage.value);

        const {
            state: simulation,
            error,
            isLoading: isFetchingSimulation,
            execute: loadSimulation,
        } = useAsyncState<Simulation | undefined>(
            async () => {
                const scenarioPromise = EdgeFunctionsManager.getScenarioDefinition({
                    simulationID: simulationID.value,
                });

                const [simulationBasics, teams, results, decisions, definition] = await Promise.all([
                    getSimulation(simulationID.value),
                    getTeamsWithUsers(simulationID.value, scenarioPromise),
                    getSimulationResults(simulationID.value),
                    getSimulationDecisions(simulationID.value),
                    scenarioPromise,
                ]);
                if (isNullable(simulationBasics) || isNullable(decisions)) {
                    throw new FrontendError('errors.frontend.simulationFetchFailed');
                }

                if (definition.id === 'mte-apply-cms') {
                    const { data: elements } = await supabaseClient.from('elements').select('*');
                    const { data: translations } = await supabaseClient.from('translations').select('*');

                    const translationsByElementIdAndLanguage: Record<
                        string,
                        Record<string, Record<string, string>>
                    > = {};

                    translations!.forEach(({ element_id, language, content }) => {
                        if (!(element_id in translationsByElementIdAndLanguage)) {
                            translationsByElementIdAndLanguage[element_id] = {};
                        }
                        translationsByElementIdAndLanguage[element_id][language] = content as Record<string, string>;
                    });

                    addElements(
                        elements!.map((element: any) => ({
                            id: element.id,
                            type: element.type,
                            element: element.config,
                            // TODO: Allow other languages
                            translations: translationsByElementIdAndLanguage[element.id],
                        })),
                    );

                    const scenarioElement = getElementsByType('scenario')[0];
                    const decisionConfigs = getElementsByType(decisionElementTypes);

                    definition.navigationBar = scenarioElement.resolvedElement.navigationBar;
                    definition.possiblePeriods = scenarioElement.resolvedElement.possiblePeriods;
                    definition.decisions = Object.fromEntries(
                        decisionConfigs.map((decision) => [decision.resolvedElement.name, decision.resolvedElement]),
                    );
                }

                // TODO: Make it reactive by listening to language changes
                // -------------
                // const translations = await EdgeFunctionsManager.getScenarioTranslation({
                //     scenarioID: simulationBasics.scenarioId,
                //     language: 'en',
                // });

                // (translations?.data as any[]).forEach((translation) => {
                //     const key = translation.element_id;
                //     if (key in definition.decisions) {
                //         const newDef = fillContent(definition.decisions[key], translation.content, []);
                //         definition.decisions[key] = newDef;
                //     }
                // });
                // -------------

                const {
                    id,
                    name,
                    periodKey,
                    isPeriodUnlocked,
                    startDate,
                    endDate,
                    periodEndTime,
                    modifiers,
                    isInstructorManaged,
                } = simulationBasics;

                return {
                    id,
                    name,
                    isInstructorManaged,
                    modifiers: modifiers ?? undefined,
                    periodKey,
                    isPeriodUnlocked,
                    periodEndTime: isNullable(periodEndTime) ? undefined : DateTime.fromISO(periodEndTime),
                    teams,
                    results,
                    decisions,
                    scenario: definition,
                    startDate: DateTime.fromISO(startDate),
                    endDate: DateTime.fromISO(endDate),
                };
            },
            undefined,
            { immediate: false, resetOnExecute: false },
        );

        const simulationLoadingError = computed(() => (error.value as Error)?.message);
        const hasSimulationInstanceLoadedSuccessfully = computed(
            () => !isFetchingSimulation.value && isNullable(simulationLoadingError.value),
        );

        watch(
            simulationID,
            (_, __, onCleanup) => {
                loadSimulation();

                const simulationListener = listenToSimulation(simulationID.value, (simulationBasics) => {
                    if (isNullable(simulation.value) || isNullable(simulationBasics)) {
                        throw new Error('Simulation not found! Simulation basics were deleted.');
                    }

                    const { id, name, periodKey, isPeriodUnlocked, endDate, periodEndTime, modifiers } =
                        simulationBasics;

                    simulation.value = {
                        ...simulation.value,
                        id,
                        name,
                        modifiers: modifiers ?? undefined,
                        periodKey,
                        isPeriodUnlocked,
                        endDate: DateTime.fromISO(endDate),
                        periodEndTime: isNullable(periodEndTime) ? undefined : DateTime.fromISO(periodEndTime),
                    };
                });
                const simulationResultsListener = listenToSimulationResults(simulationID.value, (simulationResults) => {
                    if (isNullable(simulation.value) || isNullable(simulationResults)) {
                        throw new Error('Simulation not found! Simulation results were deleted.');
                    }
                    const { data, periodKey, teamKey } = simulationResults;
                    const currentData = simulation.value.results;
                    currentData[periodKey] = {
                        ...currentData[periodKey],
                        [teamKey]: data,
                    };
                    // We have to make sure to change the reference of the object to trigger reactivity
                    simulation.value = {
                        ...simulation.value,
                        results: currentData,
                    };
                });
                const simulationDecisionsListener = listenToSimulationDecisions(
                    simulationID.value,
                    (simulationDecisions) => {
                        if (isNullable(simulation.value) || isNullable(simulationDecisions)) {
                            throw new Error('Simulation not found! Simulation decisions were deleted.');
                        }
                        const { decisions, initialDecisions, periodKey, hasBeenSubmitted, teamKey } =
                            simulationDecisions;
                        const currentDecisions = simulation.value.decisions;
                        simulation.value.decisions[periodKey] = {
                            ...currentDecisions[periodKey],
                            [teamKey]: {
                                decisions,
                                initialDecisions,
                                hasBeenSubmitted,
                            },
                        };
                        // We have to make sure to change the reference of the object to trigger reactivity
                        simulation.value = {
                            ...simulation.value,
                            decisions: currentDecisions,
                        };
                    },
                );

                onCleanup(() => {
                    simulationListener.unsubscribe();
                    simulationResultsListener.unsubscribe();
                    simulationDecisionsListener.unsubscribe();
                });
            },
            { immediate: true },
        );

        useSimulationStorage(simulation);

        return {
            simulation,
            hasSimulationInstanceLoadedSuccessfully,
            simulationLoadingError,
        };
    },
);

export function useSimulation(): Ref<Simulation | undefined> {
    return useSimulationInstanceStore()?.simulation ?? ref(undefined);
}

/**
 * Returns the simulation instance if it is loaded, otherwise throws an error.
 * Only use this in areas where the simulation instance is loaded and injected.
 */
export function useNonNullSimulation(): Ref<Simulation> {
    const simulationInstanceStore = useSimulationInstanceStore();
    if (isNullable(simulationInstanceStore)) {
        throw new FrontendError('errors.frontend.simulationNotProvided');
    }

    return computed(() => {
        if (isNullable(simulationInstanceStore.simulation.value)) {
            throw new FrontendError('errors.frontend.simulationNotLoaded');
        }
        return simulationInstanceStore.simulation.value!;
    });
}

export function getScenarioAssetUrl(scenario: FrontendScenarioDefinition, fileName: string): string {
    return supabaseClient.storage.from('scenario_assets').getPublicUrl(`${scenario.assetsDirectory}/${fileName}`).data
        .publicUrl;
}
